import { StaticQuery, Link } from "gatsby"
import React, { useEffect, useRef } from "react"
import gsap from "gsap"
import { ScrollToPlugin } from "gsap/all"
import { isMobile } from "react-device-detect"

import ScrollSection from "../locomotiveScroll/scrollSection"
import SplitText from "../split-text/split-text"

// InView detector
import "intersection-observer"
import { InView } from "react-intersection-observer"

// SVG
import Icon from "./../../../static/images/svg/icon.inline.svg"
// import Arrow from "./../../../static/images/svg/button.inline.svg"

// import Circle from "./../../../static/images/svg/button-circle.inline.svg"

import Circle from "./../../../static/images/svg/button-circle2.inline.svg"
import Arrow from "./../../../static/images/svg/to-top-arrow.inline.svg"
import LinkedIn from "./../../../static/images/svg/linkedin.inline.svg"

import styles from "./footer.module.scss"

const navigation = [
  [
    {
      label: "Home",
      link: "/",
    },
    {
      label: "About",
      link: "/about",
    },
    {
      label: "Our Strategy",
      link: "/our-strategy",
    },
    {
      label: "Our Focus",
      link: "/our-focus",
    },
  ],
  [
    {
      label: "Our Team",
      link: "/team",
    },
    {
      label: "Careers",
      link: "/careers",
    },
    {
      label: "Healthcare AI",
      link: "/healthcare-ai",
    },
    {
      label: "Press",
      link: "/press",
    },
   
  ],
  [  {
    label: "Summit",
    link: "/summit",
  }]
]

// Register plugins
gsap.registerPlugin(ScrollToPlugin)

const Footer = ({ siteTitle }) => {
  const topButton = useRef()

  useEffect(() => {
    const { current } = topButton

    const toTop = e => {
      e.preventDefault()
      if( typeof window != "undefined"){

    
      if (isMobile) {
        gsap.to(window, { scrollTo: 0, duration: 2 })
      } else {
        if (window.scroll.scrollTo) {
          window.scroll.scrollTo("top")
        }
      }
    }
    }

    if (!current) return
    current.addEventListener("click", toTop)

    return () => {
      if (!current) return
      current.removeEventListener("click", toTop)
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
        query SocialQuery {
          allContentfulContactModal {
            edges {
              node {
                instagramUsername
                linkedInUrl
                address
                mediumUrl
                twitterUsername
                contentful_id
              }
            }
          }
        }
      `}
      render={data => (
        <ScrollSection>
          <InView triggerOnce={true}>
            {({ inView, ref, entry }) => (
              <footer
                className={styles.footer}
                ref={ref}
                inview={inView.toString()}
              >
                <div className={`container`}>
                  <div className={`cols`}>
                    <div className={`col m12`}>
                      <div className={styles.icon}>
                        <Icon />
                      </div>
                      <div className={`footer-nav ${styles.navigation}`}>
                        {navigation.map(navList => (
                          <ul>
                            {navList.map(navItem => (
                              <li>
                                <Link to={navItem.link}>
                                  <span className={styles.mainText}>
                                    {navItem.label}
                                  </span>
                                  <span className={styles.subText}>
                                    {navItem.label}
                                  </span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        ))}
                      </div>
                      <div className={styles.address}>
                        <SplitText
                          addressSplit
                          classString={`copy copy--f ${styles.copy}`}
                        >
                          <a
                            href="https://maps.google.com/?q=373%20Park%20Avenue%20South,%202nd%20Floor,New%20York%20NY%2010016"
                            target="__blank"
                          >
                            {
                              data.allContentfulContactModal.edges[0].node
                                .address
                            }
                          </a>
                          <a href="mailto:hello@aegisventures.com">
                            hello@aegisventures.com
                          </a>
                        </SplitText>
                        <div className={`${styles.socialIcon}`}>
                          <a
                            href={`${data.allContentfulContactModal.edges[0].node.linkedInUrl}`}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={`Aegis Labs' LinkedIn`}
                          >
                            <LinkedIn />
                          </a>
                        </div>
                      </div>
                      <div className={styles.socials}>
                        <ul></ul>
                      </div>
                      <div className={styles.copyright}>
                        <span className={`copy ${styles.year}`}>
                          © {new Date().getFullYear()} — Aegis Ventures
                        </span>
                        <span className={`copy ${styles.madeby}`}>
                          Site made by{" "}
                          <a
                            href="https://toyfight.co"
                            target="_blank"
                            rel="noreferrer"
                          >
                            ToyFight
                          </a>
                        </span>
                      </div>
                      <div className={styles.buttonwrap}>
                        <button
                          className={styles.arrow}
                          ref={topButton}
                          aria-label="To top of page"
                        >
                          <span className={styles.circle}>
                            <Circle />
                            <span className={styles.arrowWrap}>
                              <span className={styles.arrowShape}>
                                <Arrow />
                              </span>
                              <span className={styles.arrowShape}>
                                <Arrow />
                              </span>
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            )}
          </InView>
        </ScrollSection>
      )}
    />
  )
}

export default Footer
